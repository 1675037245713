(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/article-column/assets/javascripts/article-column.js') >= 0) return;  svs.modules.push('/widgets/article-column/assets/javascripts/article-column.js');
"use strict";


(function (svs) {
  'use strict';

  const logger = new svs.core.log.Logger('widget:article_column');
  svs.widget = svs.widget || {};
  svs.widget.article_column = svs.widget.article_column || {};
  svs.widget.article_column.fetchData = (options, data, callback) => {
    options.fallbackImageWidth = '640';
    try {
      return svs.content.simple_article.contentLoader.loadArticles(options, (err, model) => {
        if (err) {
          logger.warn("Error loading article widget content: ".concat(err.toString()));
          callback(null);
        } else {
          model.forEach((article, index) => {
            if (article.images) {
              article.images[0].sizes = svs.content.shared.imageSizes.getMediaQueryForColumn(index, model);
            }
          });
          data.articles = model;
          callback(null, data);
        }
      });
    } catch (e) {
      logger.error("Error loading widget: ".concat(e.toString()));
      callback(e);
    }
  };
})(svs);

 })(window);